import { useState, useContext } from 'react';
import { Modal, Form} from 'react-bootstrap';
import { GlobalContext } from '../../store/GlobalState';
import './WorkItemReassignModal.css';

const WorkItemReassignModal = (props) => {
  const { hubConnection, users, selectedWorkItem } = useContext(GlobalContext);

  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line
  const [activeUsers, setActiveUsers] = useState(users.filter(user => user.isActive));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedCheckbox === null) setValidated(false);

    if (hubConnection) {
      try {
      await hubConnection.invoke('ReAssignWorkItem', selectedWorkItem.id, selectedCheckbox);
      }
      catch (err) {
        console.warn(err);
      }

      props.onHide(false);
    }
  };

  const handleSelect = (e) => {
    if (!e.target.checked) setSelectedCheckbox(null);
    else setSelectedCheckbox(e.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reassign Work Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
          { activeUsers.map(user => {
            let label = (<>{user?.firstName} {user?.lastName}{user?.attUserId !== "" ? ` (${user?.attUserId})` : ''} <a href={`mailto:${user?.email}`}>{`<${user?.email}>`}</a></>);
            return (<Form.Check id={`assign-user-id=${user?.id}`} onChange={(e) => handleSelect(e)} key={user?.id} type="checkbox" value={user?.id} label={label} checked={selectedCheckbox === user?.id} />);
          })}

          <button type="submit" className="reassign-user-button btn btn-success btn-block">
            Reassign
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default WorkItemReassignModal;
