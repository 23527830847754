import { useContext } from 'react';
import { GlobalContext } from '../../store/GlobalState';
import { string, array, func } from 'prop-types';
import WorkItemPillBox from './WorkItemPillBox';
import './WorkItemSection.css';

const propTypes = {
  id: string,
  sectionTitle: string.isRequired,
  workItems: array,
  setWorkItemId: func,
  onDrop: func,
  onDragOver: func,
};

const WorkItemSection = ({ id, sectionTitle, workItems, setWorkItemId, onDrop, onDragOver }) => {
  const { workItemFilter } = useContext(GlobalContext);

  const workItemCallback = (workItem) => {
    if (workItemFilter !== 'all' && workItemFilter !== '') {
      if (workItem.assignedUserName === workItemFilter)
        return <WorkItemPillBox key={workItem.locationId} workItem={workItem} onClick={() => setWorkItemId(workItem.id)} />;
    } else return <WorkItemPillBox key={workItem.locationId} workItem={workItem} onClick={() => setWorkItemId(workItem.id)} />;
  
  }

  return (
      <div className="work-item-section">
        <h5 className="work-item-section-title">{sectionTitle}</h5>
        <div id={id} className="work-items-container" onDrop={onDrop} onDragOver={onDragOver}>
          { workItems.map(workItemCallback)}
        </div>
      </div>
  );
};

WorkItemSection.propTypes = propTypes;

export default WorkItemSection;
