/**
 *
 * @param {string} token The JWT token.
 * @returns A header object for API requests - for convenience.
 */
 export const authorizedHeaders = (token) => ({
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json'
});


//export const apiUrl = process.env.REACT_APP_API_URL;


/**
 * Convenience variable storing API URL.
 */
export const apiUrl = process.env.REACT_APP_API_URL;
/**
 * Convenience variable storing hub URL.
 */
export const hubUrl = process.env.REACT_APP_HUB_URL;

/**
 * Local User helper for making API requests.
 */
export const localUser = {
  email: 'johndoe@gmail.com',
  password: 'AVerySecurePass1!'
};
