export const DayOfWeek = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
  };

/**
 * Retrieves the DayOfWeek (enum) key by value.
 * @param {string} name
 * @returns {int} The key value.
 */
export const getDayOfWeekKeyByName = (name) => parseInt(Object.keys(DayOfWeek)[Object.values(DayOfWeek).findIndex(o => o === name)]);

/**
 * Retrieves the value for the gen key.
 * @param {int} key 
 * @returns {string} The day name.
 */
export const getDayOfWeekNameByKey = (key) => DayOfWeek[key];

// Example option generation from "enum" JS object.
//export const getDayOfWeekOptions = () => Object.entries(DayOfWeek).map(([key, value]) => { return {value: key, text: value}; })