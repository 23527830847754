/**
 * Object for designating location types. 
 * Location types use bytes behind them in the hub, so here we're mimicking that behavior.
 */
export const LocationType = {

  0: 'Truck',
  1: 'Staging Telco',
  2: 'Storage WC',
  3: 'ALC'
};

/**
 * Object for designatinng a work item's status type.
 * Status types use bytes behind them in the hub, so we're mimicking the behavior.
 */
export const StatusType = {
  PendingSoftClose: 0,
  ETRAKSoftClose: 1,
  RecentlyReconciled: 2,
};
