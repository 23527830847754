import { Nav } from 'react-bootstrap';
import { node, string, bool } from 'prop-types';
import './Drawer.css';

// Note: We should consider looking at the OffCanvas Element here: 
// https://react-bootstrap.github.io/components/offcanvas/

const propTypes = {
    children: node.isRequired,
    className: string,
    overlay: bool,
    right: bool,
};

const Drawer = ({ children, className, overlay, right }) => {
    const drawerClass = `${className} ${overlay && right ? 'drawer-overlay-right-align' : ''} ${overlay ? 'drawer-overlay' : ''} drawer`;
    
    return (
        <Nav className={drawerClass}>
            {children}
        </Nav>
    );
}

Drawer.propTypes = propTypes;

export default Drawer;
