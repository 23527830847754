import { useContext } from 'react';
import { GlobalContext } from '../../store/GlobalState';
import { object } from 'prop-types';
import { Form } from 'react-bootstrap';
import './UserToggle.css';

const propTypes = {
  user: object.isRequired
};

const UserToggle = ({ user }) => {
  const { hubConnection } = useContext(GlobalContext);

  const handleClick = (e) => {
    e.preventDefault();

    if (hubConnection)
      hubConnection.invoke('ToggleUserActivity', user.id);
  };

// background: user.isActive ? '#58b97f' : '#c21b5b'

  return (
    <Form.Switch
      inline
      aria-label="Active"
      checked={user.isActive}
      onChange={handleClick}
    />
  );
};

UserToggle.propTypes = propTypes;

export default UserToggle;
