import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalProvider } from './store/GlobalState';
import Toolbar from './components/Utilities/Toolbar';
import Body from './components/Body';
import InvitationPage from './components/Pages/Invitation/InvitationPage';
import UserInvitationPage from './components/Pages/UserInvitation/UserInvitationPage';
import UploadsPage from './components/Pages/Uploads/UploadsPage';
import UserSchedulePage from './components/Pages/UserSchedule/UserSchedulePage';
import WorkItemPage from './components/Pages/WorkItem/WorkItemPage';
import UsersPage from './components/Pages/User/UsersPage';
import RightDrawer from './components/Utilities/Drawers/RightDrawer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [rightDrawerState, setRightDrawerState] = useState(false);

  const toggleDrawer = (e) => {
    setRightDrawerState(!rightDrawerState);
  };

  return (
    <GlobalProvider>
      <div className="telkore-navbar">
        <div className="page-container">
          <Toolbar />
        </div>
        {rightDrawerState && <RightDrawer toggleDrawer={toggleDrawer} />}
      </div>
      <Body>
        <BrowserRouter>
          <Routes>
            <Route path='/invitation/:token' element={<InvitationPage />} />
            <Route exact path='/users/invitations' element={<UserInvitationPage />} />
            <Route exact path='/uploads' element={<UploadsPage />} />
            <Route exact path='/users/:userId' element={<UserSchedulePage />} />
            <Route exact path='/users' element={<UsersPage />} />
            <Route exact path="/" element={<WorkItemPage />} />
          </Routes>
        </BrowserRouter>
      </Body>
    </GlobalProvider>
  );
}

export default App;
