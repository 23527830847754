import { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { GlobalContext } from '../../../store/GlobalState';
import dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjsTimezone from 'dayjs/plugin/timezone';
import WorkItemSection from '../../WorkItems/WorkItemSection';
import './WorkItemPage.css';
import WorkItemDrawer from './WorkItemDrawer';
import { getCookieValue, telkoreAdminFlag } from '../../../utilities/cookieHelpers';

const WorkItemPage = () => {
  dayjs.extend(dayjsUtc);
  dayjs.extend(dayjsTimezone);
  dayjs.extend(dayjsLocalizedFormat);

  //const localNow = dayjs.tz();
  // Note:
  //   Deserializes to NodaTime.OffsetDatetime
  //   We use this to determine the day-of-the week for the user & to use this instant in time
  //   to check for availability
  //localNow.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

  const {
    pendingWorkItems,
    softClosedWorkItems,
    recentlyClosedWorkItems,
    users,
    assignWorkItem,
    assignWorkItemFilter,
    selectedWorkItem,
    hubConnection,
    requestorId } = useContext(GlobalContext);

  const [progress, setProgress] = useState(0);
  const [isObjectEmpty, setIsObjectEmpty] = useState(true);
  const [userFilterSelect, setFilterSelect] = useState(<></>);

  const onWorkItemIdSet = (newWorkItemId) => {
    if ((selectedWorkItem.id === newWorkItemId) || !selectedWorkItem) {
      assignWorkItem({});
      setIsObjectEmpty(true);
    }
    else {
      if (hubConnection) hubConnection.invoke("GetWorkItem", newWorkItemId);

      if (selectedWorkItem) setIsObjectEmpty(false);
    }
  };

  // Moved here because we were getting janky console errors.
  const isWorkItemEmpty = () => Object.keys(selectedWorkItem).length === 0;

  useEffect(() => {
    const totalWorkItems =
      pendingWorkItems.length +
      softClosedWorkItems.length +
      recentlyClosedWorkItems.length;

    const percentage = (recentlyClosedWorkItems.length / totalWorkItems) * 100;
    setProgress(percentage);

    // eslint-disable-next-line
    if (getCookieValue(telkoreAdminFlag) == 0) {
      assignWorkItemFilter(requestorId);

      setFilterSelect(
        <Form.Select id="user-filter-select" onChange={(e) => handleSelect(e)}>
          <option key={'all'} value="all">All</option>
          {users.map((user) => {
            if (user.userName === requestorId) {
              return <option selected key={user.userName} value={user.userName}>{user.userName}</option>

            }

            return <option key={user.userName} value={user.userName}>{user.userName}</option>
          })}
        </Form.Select>);
    }
    else {
      assignWorkItemFilter('');

      setFilterSelect(
        <Form.Select id="user-filter-select" onChange={(e) => handleSelect(e)}>
          <option key={'all'} value="all">All</option>
          {users.map((user) => (<option key={user.userName} value={user.userName}>{user.userName}</option>))}
        </Form.Select>);
    };
    // eslint-disable-next-line
  }, [pendingWorkItems.length, recentlyClosedWorkItems.length, softClosedWorkItems.length, users]);

  const dragDrop = (e) => {
    e.preventDefault();
    const workItemId = e.dataTransfer.getData('work_item_id');

    let targetByte;
    if (e.target.id === 'pending')
      targetByte = 0;
    else if (e.target.id === 'softClose')
      targetByte = 1;
    else if (e.target.id === 'recentlyClosed')
      targetByte = 2;

    assignWorkItem({});
    setIsObjectEmpty(true);

    if (hubConnection)
      hubConnection.invoke('UpdateWorkItemStatus', workItemId, targetByte);
  };

  const dragOver = (e) => e.preventDefault();

  const handleSelect = (e) => assignWorkItemFilter(e.target.value);

  return (
    <div className="d-flex">
      <div className="work-item-page">
        <div className="username-select">
          Filter by User:
          {userFilterSelect}
        </div>
        <div className="progress-bar-outer">
          <div
            className="progress-bar-inner"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <WorkItemSection
          id="pending"
          sectionTitle={"Pending Soft Close in ETRAK"}
          workItems={pendingWorkItems}
          setWorkItemId={onWorkItemIdSet}
          onDrop={dragDrop}
          onDragOver={dragOver}
        />
        <WorkItemSection
          id="softClose"
          sectionTitle={"Soft Closed in ETRAK"}
          workItems={softClosedWorkItems}
          setWorkItemId={onWorkItemIdSet}
          onDrop={dragDrop}
          onDragOver={dragOver}
        />
        <WorkItemSection
          id="recentlyClosed"
          sectionTitle={"Recently Reconciled"}
          workItems={recentlyClosedWorkItems}
          setWorkItemId={onWorkItemIdSet}
          onDrop={dragDrop}
          onDragOver={dragOver}
        />
      </div>
      {!isObjectEmpty && !isWorkItemEmpty() && <WorkItemDrawer />}
    </div>
  );
};

export default WorkItemPage;