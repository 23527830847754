import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { GlobalContext } from "../../../store/GlobalState";
import { apiUrl } from '../../../utilities/requestHelpers';
import { createToast } from '../../../utilities/toastHelpers';

/*
 * Steps:
 *  URL Decode token: unescape(token)
 *  Send token to serve to validate
 *  If token has not expired AND has not already been accepted
 *    Display Form
 *  Otherwise
 *    Display appropriate message - we'll say it may be expired or invalid regardless of the situation - no 100% confirmation here.
 *  On Accept:
 *    Validate user input
 *    Check for username availability
 *    If Username not taken, continue
 *    Otherwise, show error message
 *    Upon completing invitation, authenticate user with username/password the user provided.
 *    (Maybe) Server: Sends email notification that invitation was accepted.
 *    Redirect to main page.
 *  On Decline
 *    Send to server
 *      Update invitation
 *      (Maybe) update user that created invitation via email.
 */

/**
 * @typedef TokenMeta
 * @type {object}
 * @property {boolean} isExpired - `true` if the invitation code is expired, otherwise `false`
 * @property {string} status - the invitation status
 */

/**
 * @typedef TokenUserData
 * @property {string} id - the invitation identifier
 * @property {string} token - the invitation token (full token string)
 * @property {string} userId - the user identifier
 * @property {string} firstName - the first name
 * @property {string} lastName - the last name
 * @property {string} email - the email address
 */

const InvitationPage = () => {
  const {assignJwtToken, toggleToasts} = useContext(GlobalContext);
  const {token} = useParams();
  const [tokenMeta, setTokenMeta] = useState(null);
  const [tokenIsInvalid, setTokenIsInvalid] = useState(null);
  const [tokenUserData, setTokenUserData] = useState(null);
  const [initialRequestActive, setInitialRequestActive] = useState(true);
  
  const pendingStatus = "Pending";
  const userNameValidationDefaultValidationFeedback = 'Please enter a user name.';
  const passwordValidationDefaultFeedback = 'Please specify a password.';
  const confirmPasswordDefaultFeedback = 'Your passwords do not match.';

  // --------------------------------------------------------------------------
  // Form Fields & State
  const [userName, setUserName] = useState('');
  const [userNameIsValid, setUserNameIsValid] = useState(null);
  const [userNameValidationFeedback, setUserNameValidationFeedback] = useState(userNameValidationDefaultValidationFeedback);
  const [password, setPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(null);
  const [passwordValidationFeedback, setPasswordValidationFeedback] = useState(passwordValidationDefaultFeedback);
  const [passwordRevealed, setPasswordRevealed] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(null);
  const [confirmPasswordValidationFeedback, setConfirmPasswordValidationFeedback] = useState(confirmPasswordDefaultFeedback);
  const [confirmPasswordRevealed, setConfirmPasswordRevealed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submissionActive, setSubmissionActive] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  // eslint-disable-next-line
  const [autoAuthActive, setAutoAuthActive] = useState(false);
  // eslint-disable-next-line
  const [autoAuthSuccess, setAutoAuthSuccess] = useState(null);

  // --------------------------------------------------------------------------
  // Form Validation
  const validateUserName = () => {
    if (userName === '' || userName === null) {
      setUserNameValidationFeedback(userNameValidationDefaultValidationFeedback);
      setUserNameIsValid(false);
    }
    else {
      setUserNameIsValid(true);
    }
  }

  // Microsoft recommened banned passwords.
  const bannedPasswords = ['abcdefg', 'password', 'monkey'];

  const validatePassword = () => {
    if (password === '' || password === null) {
      setPasswordValidationFeedback(passwordValidationDefaultFeedback);
      setPasswordIsValid(false);
    }
    else if (password?.length <= 7) {
      setPasswordValidationFeedback('Please use at least 8 characters.');
      setPasswordIsValid(false);
    }
    else if (bannedPasswords.some((word) => word === password)) {
      setPasswordValidationFeedback('Invalid password.');
      setPasswordIsValid(false);
    }
    else {
      setPasswordIsValid(true);
    }
  };

  const validateConfirmPassword = () => {
    if (confirmPassword === '' || confirmPassword === null) {
      setConfirmPasswordValidationFeedback('Please confirm your password.');
      setConfirmPasswordIsValid(false);
    }
    else if (confirmPassword?.length <= 7) {
      setConfirmPasswordValidationFeedback('Please use at least 8 characters.');
      setConfirmPasswordIsValid(false);
    }
    else if (bannedPasswords.some((word) => word === confirmPassword)) {
      setConfirmPasswordValidationFeedback('Invalid password.');
      setConfirmPasswordIsValid(false);
    }
    else if (confirmPassword !== password) {
      setConfirmPasswordValidationFeedback(confirmPasswordDefaultFeedback);
      setConfirmPasswordIsValid(false);
    }
    else {
      setConfirmPasswordIsValid(true);
    }
  };

  const validateAll = () => {
    validateUserName();
    validatePassword();
    validateConfirmPassword();
  };

  // --------------------------------------------------------------------------
  // Form Submission

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setSubmitted(true);

    validateAll();

    if (userNameIsValid === false && passwordIsValid === false && confirmPasswordIsValid === false) {
      e.stopPropagation();
    }
    
    const acceptedInvitation = {
      invitationId: tokenUserData?.id,
      token: tokenUserData?.token,
      userId: tokenUserData?.userId,
      userName,
      password
    };

    try {
      setSubmissionActive(true);
      console.log('acceptedInvitation: %o', acceptedInvitation);

      const response = await axios.post(
        `${apiUrl}/invitation/${encodeURIComponent(token)}/confirm`,
        acceptedInvitation
      );

      if (response.status === 200){
        const data = await response.data;
        console.log('Accept data: %o', data);
        setSubmissionSuccess(true);

        await authenticateAndRedirect();
      }
      else {
        // Handle non-200 status code(s)?
        console.warn(response);
      }
      setSubmissionActive(false);
    } catch (err) {
      const response = err.response;

      if (response.status === 400) {
        var errors = await response.data;

        if (errors.includes('User name already in use.')) {
          e.stopPropagation();
          setUserNameValidationFeedback('User name already in use. Please try again, or reach out to Telkore staff.');
          setUserNameIsValid(false);
          setSubmissionActive(false);
        }
      }

      console.warn(err);
    }
  };

  // --------------------------------------------------------------------------
  // API Calls
  const getMetadataByToken = (theToken) => {
    setInitialRequestActive(true);

    axios.get(`${apiUrl}/invitation/${theToken}`)
          .then((response) => {
            if (response.status === 200) {
              setTokenMeta(response.data);
            }
            setInitialRequestActive(false);
          })
          .catch((err) => {
            setTokenMeta([err]);
            setTimeout(() => {
              setInitialRequestActive(false);
            }, 250); 
          });
  };

  const getUserDataByToken = (theToken) => {
      axios.get(`${apiUrl}/invitation/${theToken}/details`)
           .then((response) => {
            if (response.status === 200) {
              setTokenUserData(response.data);
            }
           })
           .catch((err) => {
            console.warn(err);
            setTokenUserData(null);
           });
  };

  const authenticateAndRedirect = async () => {
    setAutoAuthActive(true);
    
    const user = {
      userName,
      password
    };

    try {
      const response = await axios.post(
        `${apiUrl}/authentication/login`,
        user,
      );
  
      if (response.status === 200) {
        const data = await response.data;
        assignJwtToken(data.token, data.group);

        setAutoAuthActive(false);
        setAutoAuthSuccess(true);
        setTimeout(() => { window.location.href = `${process.env.REACT_APP_BASE_APP_PATH}/`; }, 10000);

      }
    } catch (err) {
      toggleToasts(createToast('danger', 'Failed to log in.'));
      setAutoAuthSuccess(false);
    }
  }

  // --------------------------------------------------------------------------
  // After submission, watch values for changes to re-validate
  useEffect(() => {
    if (submitted === true) {
      validateUserName();
    }
    // eslint-disable-next-line
  }, [submitted, userName]);

  useEffect(() => {
    if (submitted === true) {
      validatePassword();
    }
    // eslint-disable-next-line
  }, [submitted, password]);

  useEffect(() => {
    if (submitted === true) {
      // eslint-disable-next-line
      validateConfirmPassword();
    }
    // eslint-disable-next-line
  }, [submitted, confirmPassword]);

  // --------------------------------------------------------------------------
  // Initialization/Setup

  // This checks the invitation metadata & determines whether it's still valid.
  // This get's invitation user identifiable data if the token metadata is valid.
  useEffect(() => {
    setTokenIsInvalid(tokenMeta === null 
      || (tokenMeta !== null 
      && (
        Array.isArray(tokenMeta)
        || tokenMeta?.isExpired === true 
        || (tokenMeta?.isExpired === false && tokenMeta?.status !== pendingStatus)
        )
      ));
  }, [tokenMeta]);

  useEffect(() => {
    if (tokenMeta !== null && tokenIsInvalid === false) {
      getUserDataByToken(encodeURIComponent(token));
    }  
    // eslint-disable-next-line
  }, [tokenMeta, tokenIsInvalid]);

  // This is a one-time call (according to the docs) that gets the token metadata
  useEffect(() => {
    getMetadataByToken(encodeURIComponent(token));
    // eslint-disable-next-line
  }, []); // called only once <- according to the docs!



  // --------------------------------------------------------------------------
  // User Interface

  const submitButtonContent = submissionActive === true
    ? (<>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      /> Loading...
      </>)
    : 'Accept & Continue';

  const invitationForm = (
    <Form noValidate onSubmit={(e) => handleFormSubmit(e)}>
      <Row>
        <Col className="mb-3">
          <h2>Welcome {tokenUserData?.firstName}!</h2>
          <p>To accept your invitation to use the scheduler, please complete the form below to setup your user account.</p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Form.Group>
            <Form.Label>User Name:</Form.Label>
            <Form.Control 
              id="username" 
              type="text" 
              value={userName} 
              placeholder="User Name" 
              required                 
              onChange={(e) => setUserName(e.target.value)} 
              isValid={userNameIsValid !== null && userNameIsValid} 
              isInvalid={userNameIsValid !== null && !userNameIsValid}
            />
            <Form.Control.Feedback type="invalid">{userNameValidationFeedback}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Form.Group>
            <Form.Label>Password:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control 
                id="password" 
                type={passwordRevealed ? 'text' : 'password'} 
                value={password} 
                placeholder="Password" 
                required 
                onChange={(e) => setPassword(e.target.value)}
                isValid={passwordIsValid !== null && passwordIsValid} 
                isInvalid={passwordIsValid !== null && !passwordIsValid}
              />
              <Button variant="outline-secondary" onClick={(e) => setPasswordRevealed(!passwordRevealed)}>
                <FontAwesomeIcon icon={passwordRevealed ? faEye : faEyeSlash} />
              </Button>
              <Form.Control.Feedback type="invalid">{passwordValidationFeedback}</Form.Control.Feedback>
            </InputGroup>
            <Form.Text muted>
              Your password must be at least 8 characters in length. It's recommened that it contains mixed case letters, numbers, 
              and special characters.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Form.Group>
            <Form.Label>Verify Password:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control 
                id="confirmPassword" 
                type={confirmPasswordRevealed ? 'text' : 'password'} 
                value={confirmPassword} 
                placeholder="Verify Password" 
                required                 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                isValid={confirmPasswordIsValid !== null && confirmPasswordIsValid} 
                isInvalid={confirmPasswordIsValid !== null && !confirmPasswordIsValid} 
              />
              <Button variant="outline-secondary" onClick={(e) => setConfirmPasswordRevealed(!confirmPasswordRevealed)}>
                <FontAwesomeIcon icon={confirmPasswordRevealed ? faEye : faEyeSlash} />
              </Button>
              <Form.Control.Feedback type="invalid">{confirmPasswordValidationFeedback}</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 d-flex justify-content-end">
          <Button className="accept-email-invitation" type="submit" disabled={submissionActive} variant="primary">
            {submitButtonContent}
          </Button>
        </Col>
      </Row>      
    </Form>
  );

  const invitationSuccess = (
    <>
      <p className="m-5">Thank you for confirming your invitation to use scheduler!</p>
      <p className="m-5">Please wait one moment while we are signing you in.  You may also sign in by click <a href={`${process.env.REACT_APP_BASE_APP_PATH}/`}>here</a>.</p>
    </>
  )

  const invalidTokenMessage = (
    <p className="m-5 text-center">This invitation link has been used, expired or is invalid.  If you believe you have reached this message in error, please contact Telkore staff.</p>
  );

  const loader = (
    <p className="m-5 text-center">One moment please&hellip;</p>
  )

  // Change output based on states
  const invitationContent = submissionSuccess === true
    ? invitationSuccess
    : invitationForm;


  var tokenLoadedContent = tokenIsInvalid === true
    ? invalidTokenMessage
    : invitationContent;

  var content = initialRequestActive === true
    ? loader
    : tokenLoadedContent

  return (
    <Container className="invitation-page pt-3 pb-3">
      <Row>
        <Col>
          { content }
        </Col>
      </Row>
    </Container>
  );
};

export default InvitationPage;
