import { useState, useContext, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { faSignOut, faPlusSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "../../store/GlobalState";
import WorkItemModal from "../WorkItems/WorkItemModal";
import ToolbarDrawer from './ToolbarDrawer';
import {
  getCookieValue,
  telkoreSchedToken,
} from "../../utilities/cookieHelpers";
import { createToast } from "../../utilities/toastHelpers";
import "./Toolbar.css";

const Toolbar = () => {
  const { jwtToken, isAdmin, assignJwtToken, toggleToasts, assignWorkItems, assignWorkItemFilter } = useContext(GlobalContext);
  const [workItemModalShow, setWorkItemModalShow] = useState(false);
  const [showToolbarDrawer, setShowToolbarDrawer] = useState(false);
  const breakpoint = 775;

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > breakpoint)
        setShowToolbarDrawer(false);
    });
  });

  const handleLogout = () => {
    assignJwtToken('');
    assignWorkItems([]);
    assignWorkItemFilter('');
    toggleToasts(createToast('success', 'Successfully logged out!'));
    window.location = '/';
  };

  const showNewWorkItem = isAdmin === 2;

  const newWorkItemLink = showNewWorkItem ? (
    <Nav.Item>
      <FontAwesomeIcon
        className="new-workitem-icon"
        icon={faPlusSquare}
        onClick={() => setWorkItemModalShow(true)}
      />
    </Nav.Item>
  ) : null;

  const userItemLink =
    isAdmin >= 1 && jwtToken ? (
      <Nav.Item>
        <Nav.Link href={`${process.env.REACT_APP_BASE_APP_PATH}/users`}>
          Users
        </Nav.Link>
      </Nav.Item>
    ) : null;

  const invitationItemLink =
    isAdmin === 2 && jwtToken ? (
      <Nav.Item>
        <Nav.Link
          href={`${process.env.REACT_APP_BASE_APP_PATH}/users/invitations`}
        >
          Invitations
        </Nav.Link>
      </Nav.Item>
    ) : null;

  const logoutButton =
    jwtToken !== "" && getCookieValue(telkoreSchedToken) !== "" ? (
      <Nav.Item>
        <FontAwesomeIcon
          className="logout-icon"
          icon={faSignOut}
          onClick={() => handleLogout()}
        />
      </Nav.Item>
    ) : null;

  return (
    <div>
      <Navbar variant="dark" expand="md" className="toolbar">
        <Navbar.Brand
          className="brand"
          href={`${process.env.REACT_APP_BASE_APP_PATH}/`}
        >
          Scheduler
        </Navbar.Brand>
        {jwtToken ? (
          <>
            <Nav className="links">
              <div className="work-item-group">
                <Nav.Item>
                  <Nav.Link href={`${process.env.REACT_APP_BASE_APP_PATH}/`}>
                    Work Items
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_BASE_APP_PATH}/uploads`}
                  >
                    File Uploads
                  </Nav.Link>
                </Nav.Item>
                {userItemLink}
                {invitationItemLink}
                {newWorkItemLink}
              </div>
              {logoutButton}
            </Nav>
            <Nav className="mobile-links">
              <div onClick={() => setShowToolbarDrawer(!showToolbarDrawer)} className="hamburger-menu">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Nav>
          </>
        ) : (
          <div style={{ width: "100%" }}></div>
        )}
      </Navbar>

      <WorkItemModal
        show={workItemModalShow}
        onHide={() => setWorkItemModalShow(false)}
      />

      {showToolbarDrawer && <ToolbarDrawer className="mobile-links" onClose={() => setShowToolbarDrawer(false)} logoutButton={logoutButton} userItemLink={userItemLink} invitationItemLink={invitationItemLink} newWorkItemLink={newWorkItemLink} />}
    </div>
  );
};

export default Toolbar;
