import Drawer from "./Drawers/Drawer";
import { func } from "prop-types";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import "./ToolbarDrawer.css";

const propTypes = {
  onClose: func.isRequired,
};

const ToolbarDrawer = ({
  onClose,
  logoutButton,
  userItemLink,
  invitationItemLink,
  newWorkItemLink,
}) => {
  return (
    <Drawer overlay right className="toolbar-drawer">
      <FontAwesomeIcon
        icon={faTimes}
        className="close-toolbar-drawer-button"
        onClick={onClose}
      />
      <Nav className="mobile-nav">
        <div className="mobile-item-group">
          <Nav.Item>
            <Nav.Link href={`${process.env.REACT_APP_BASE_APP_PATH}/`}>
              Work Items
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={`${process.env.REACT_APP_BASE_APP_PATH}/uploads`}>
              File Uploads
            </Nav.Link>
          </Nav.Item>
          {userItemLink}
          {invitationItemLink}
          <div className="mobile-icon-group">
            {newWorkItemLink}
            {logoutButton}
          </div>
        </div>
      </Nav>
    </Drawer>
  );
};

ToolbarDrawer.propTypes = propTypes;

export default ToolbarDrawer;
