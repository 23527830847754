export const ASSIGN_JWT_TOKEN = 'ASSIGN_JWT_TOKEN';
export const ASSIGN_ACTIVE_PAGE_TITLE = 'ASSIGN_ACTIVE_PAGE_TITLE';
export const ASSIGN_NEW_HUB_CONN = 'ASSIGN_NEW_HUB_CONN';
export const ASSIGN_REQUESTOR_ID = 'ASSIGN_REQUESTOR_ID';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_FIRST_TOAST = 'REMOVE_FIRST_TOAST';
export const ASSIGN_WORK_ITEMS = 'ASSIGN_WORK_ITEMS';
export const ASSIGN_WORK_ITEM = 'ASSIGN_WORK_ITEM';
export const ASSIGN_WORK_ITEM_FILTER = 'ASSIGN_WORK_ITEM_FILTER';
export const ADD_WORK_ITEM = 'ADD_WORK_ITEM';
export const ASSIGN_USERS = 'ASSIGN_USERS';
export const ASSIGN_INVITATIONS = 'ASSIGN_INVITATIONS';
export const ADD_INVITATION = 'ADD_INVITATION';
export const RESEND_INVITATION = 'RESEND_INVITATION';
export const ASSIGN_UPLOADS = 'ASSIGN_UPLOADS';
export const ADD_UPLOAD = 'ADD_UPLOAD';
export const REMOVE_UPLOAD = 'REMOVE_UPLOAD';
export const ASSIGN_USER_SCHEDULES = 'ASSIGN_USER_SCHEDULES';
export const ASSIGN_TIME_ZONES = 'ASSIGN_TIME_ZONES';
