import { useState, useContext } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { GlobalContext } from "../../store/GlobalState";

const UserInvitationModal = (props) => {
  const { hubConnection } = useContext(GlobalContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('');
  const [isManager, setIsManager] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);

      if (hubConnection) {
        const newEmailInvitation = {
          firstName,
          lastName,
          email,
          isManager
        };

        try {
          await hubConnection.invoke('SendInvitation', newEmailInvitation);
          props.onHide(false);
        }
        catch (err) {
          console.warn(err);
        }
  
      }
    }
  };

  return (
    <Modal {...props} className="modal-dark" size="lg" aria-labelledby="email-invitation-contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="email-invitation-contained-modal-title-vcenter">Create User Invitation</Modal.Title>
      </Modal.Header>
      <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
      <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control required type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please enter the first name.</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
                <Form.Control required type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please enter the last name.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Group className="mb-3">
                <Form.Control required type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div key="inline-radio" className="mb-3">
                <p>Will this user be a manager?</p>
                <Form.Check inline label="Yes" name="isManager" type="radio" value={true} id="inline-radio-isManager-yes" onChange={(e) => setIsManager(e.target.value === "true")} />
                <Form.Check inline label="No" name="isManager" type="radio" value={false} id="inline-radio-isManager-yes" onChange={(e) => setIsManager(e.target.value === "true")} />
              </div>
            </Col>
          </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col className="d-flex justify-content-end">
          <Button variant="outline-success" className="btn-block" type="submit">Create</Button>
        </Col>
      </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserInvitationModal;