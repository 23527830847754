import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Form, Table, Container, Row, Col, Button } from "react-bootstrap";
import dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjsTimezone from 'dayjs/plugin/timezone';
import { GlobalContext } from '../../../store/GlobalState';
import { getDayOfWeekNameByKey } from '../../UserSchedules/UserScheduleTypes';
import DailyScheduleModal from '../../UserSchedules/DailyScheduleModal';
import ExceptionScheduleModal from '../../UserSchedules/ExceptionScheduleModal';
import './UserSchedulePage.css';

const UserSchedulePage = () => {
  dayjs.extend(dayjsUtc);
  dayjs.extend(dayjsTimezone);
  dayjs.extend(dayjsLocalizedFormat);

  const { userSchedules, hubConnection } = useContext(GlobalContext);
  
  const [dailyScheduleModalShow, setDailyScheduleModalShow] = useState(false);
  const [exceptionScheduleModalShow, setExceptionScheduleModalShow] = useState(false);

  const { userId } = useParams();

  useEffect(() => {
    const getUserSchedules = async () => {
      if (hubConnection && hubConnection?.state === 'Connected') {
        hubConnection.invoke('GetSchedulesForUser', userId);
        hubConnection.invoke('GetTimeZoneIds');
      }
    };

    getUserSchedules();
  }, [userId, hubConnection, hubConnection?.state]);


  const localDateString = dayjs.tz().format('YYYY-MM-DD');

  const displayFriendlyTime = (time) => (time !== null) 
    ? dayjs(`${localDateString} ${time}`, ['YYYY-MM-DD HH:mm:ss']).format('LT') 
    : null;
  
  const handleCheckClick = (e, row) => {
    const updatedSched = { ...row, isActive: !row.isActive };
    if (hubConnection) {
      hubConnection.invoke('UpdateUserSchedule', updatedSched);
    }
  };

  const handleDeleteBtn = (e, row) => {
    if (hubConnection) {
      hubConnection.invoke('DeleteUserSchedule', row.id);
    }
  };

  const normalSchedulesColumns = [
    { heading: 'Active', field: (row) => (
      <Form.Check
        onClick={(e) => handleCheckClick(e, row)}
        inline
        aria-label="Active"
        checked={row.isActive}
        readOnly
      />
    ) },
    { heading: 'Day', field: (row) => `${getDayOfWeekNameByKey(row.entryType)}` },
    { heading: 'Start Time', field: (row) => `${displayFriendlyTime(row.startTime) ?? '--'}` },
    { heading: 'End Time', field: (row) => `${displayFriendlyTime(row.endTime) ?? '--'}` },
    { heading: 'TimeZone', field: (row) => `${row.timeZone ?? ''}` },
  ];

  const exceptionScheduleColumns = [
    { heading: 'Active', field: (row) => (
      <Form.Check
        onClick={(e) => handleCheckClick(e, row)}
        inline
        aria-label="Active"
        checked={row.isActive}
        readOnly
      />
    ) },
    { heading: 'Start Date', field: (row) => `${row.startDate ?? ''}` },
    { heading: 'Start Time', field: (row) => `${displayFriendlyTime(row.startTime) ?? '--'}`},
    { heading: 'End Date', field: (row) => `${row.endDate ?? ''}` },
    { heading: 'End Time', field: (row) => `${displayFriendlyTime(row.endTime) ?? '--'}`},
    { heading: 'TimeZone', field: (row) => `${row.timeZone ?? ''}` },
    { heading: '', field: (row) => (
      <Button
        variant="outline-danger"
        onClick={(e) => handleDeleteBtn(e, row)}
      >Delete</Button>
    )}
  ];

  const normalSchedules = userSchedules.filter((sched) => sched.entryType !== 0);
  const exceptionSchedules = userSchedules.filter((sched) => sched.entryType === 0);

  const normalSchedulesTableHeading = (
    <tr>
      { normalSchedulesColumns.map((column, index) => <th key={index}>{column.heading}</th>) }
    </tr>
  );

  const tableData = (normalSchedules.length > 0)
  ? (
    <Table striped bordered hover variant="dark">
      <thead>{ normalSchedulesTableHeading }</thead>
      <tbody>{ normalSchedules.sort((a, b) => a.entryType - b.entryType).map((row) => <tr key={row.id}>{ normalSchedulesColumns.map((column, index) => <td key={index}>{column.field(row)}</td>)}</tr>) }</tbody>
    </Table>)
    : (<p className='text-center'>There are no user schedules at this time.</p>);

  
    const exceptionTableHeading = (
      <tr>
        { exceptionScheduleColumns.map((column, index) => <th key={index}>{column.heading}</th>) }
      </tr>
    );
    const exceptionData = (exceptionSchedules.length > 0)
  ? (
    <Table striped bordered hover variant="dark">
      <thead>{ exceptionTableHeading }</thead>
      <tbody>{ exceptionSchedules.map((row) => <tr key={row.id}>{ exceptionScheduleColumns.map((column, index) => <td key={index}>{column.field(row)}</td>)}</tr>) }</tbody>
    </Table>)
    : (<p className='text-center'>There are no user schedule exceptions at this time.</p>);

  const pageBody =
    <>
      <div className="d-flex">
        <h1 className="p-2 flex-grow-1">User Schedule</h1>
      </div>
      <div className="d-flex">
        <h2 className="p-2">Daily Schedule</h2>
        <div className="header-actions p-2 align-self-center justify-content-center">
          <Button variant="outline-primary" size="sm" onClick={(() => setDailyScheduleModalShow(true))}>Edit</Button>
        </div>
      </div>
      { tableData }
      <div className="d-flex">
        <h2 className="p-2">Exceptions</h2>
        <div className="header-actions p-2 align-self-center justify-content-center">
          <Button variant="outline-primary" size="sm" onClick={(() => setExceptionScheduleModalShow(true))}>Add</Button>
        </div>
      </div>
      { exceptionData }
    </>;


    return (
      <div className='user-schedules-page'>
        <Container>
          <Row>
            <Col>
              {pageBody}
            </Col>
          </Row>
          <DailyScheduleModal dialogClassName="modal-50w" show={dailyScheduleModalShow} onHide={() => setDailyScheduleModalShow(false)}/>
          <ExceptionScheduleModal dialogClassName="modal-50w" show={exceptionScheduleModalShow} onHide={() => setExceptionScheduleModalShow(false)} />
        </Container>
      </div>
    );
};

export default UserSchedulePage;
