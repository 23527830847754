import { useContext, useState } from "react";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import { GlobalContext } from "../../../store/GlobalState";
import dayjs from 'dayjs';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserInvitationModal from '../../UserInvitation/UserInvitationModal'

/*
 * Display list of invitations separated by tabs, Pending, Accepted, Declined, Expired
 * Actions: Send new token (Pending & Expired)
 *          Create: Modal form - name, email, is manager
 */

const UserInvitationPage = () => {
  dayjs.extend(dayjsLocalizedFormat);
  
  const { 
    isAdmin, 
    jwtToken, 
    invitations 
  } = useContext(GlobalContext);

  const [userInvitationModalShow, setUserInvitationModalShow] = useState(false);

  const statusEnum = {
    "Pending": 1,
    "Accepted": 2,
    "Expired": 4,
    "Rejected": 8
  };

  let getStatusName = (statusVal) => {
    return Object.keys(statusEnum)[Object.values(statusEnum).findIndex(v => v === statusVal)];
  };

  const columns = [
    { heading: 'Name', field: (row) => `${row.user.firstName} ${row.user.lastName}` },
    { heading: 'Email', field: (row) => `${row.user.email}` },
    { heading: 'Date Created', field: (row) => dayjs(row.dateCreated).format('LLL') },
    { heading: 'Date Expires', field: (row) => dayjs(row.dateExpires).format('LLL') },
    { heading: 'Date Sent', field: (row) => row.dateSent === null ? '--' : dayjs(row.dateSent).format('LLL') },
    { heading: 'Status', field: (row) => getStatusName(row.status) }
  ];
  
  const tableHeading = (
    <tr>
      { columns.map((column) => <th>{column.heading}</th>) }
    </tr>
  );

  const tableData = (invitations.length > 0)
    ? (
      <Table striped bordered hover variant="dark">
        <thead>{ tableHeading }</thead>
        <tbody>{ invitations.map((row) => <tr>{ columns.map((column) => <td>{column.field(row)}</td>)}</tr>) }</tbody>
      </Table>)
    : (<p className="text-center">There are no invitations at this time.</p>);


  const pageBody =
    jwtToken && isAdmin === 2 ? (
      <>
        <div className="d-flex">
          <h1 className="p-2">User Invitations</h1>
          <div className="header-actions p-2 align-self-center justify-content-center">
            <Button variant="outline-primary" size="sm" onClick={(() => setUserInvitationModalShow(true))}>
              <FontAwesomeIcon title="Create Invitation" size="lg" icon={faPlusSquare} />&nbsp;
              Create Invitation
            </Button>
          </div>
        </div>
        { tableData }
      </>
    ) : (
      <p>You are not allowed to view this page.</p>
    );

  return (
    <div className="user-invitation-page">
      <Container>
        <Row>
          <Col>
            {pageBody}
          </Col>
        </Row>
        <UserInvitationModal show={userInvitationModalShow} onHide={() => setUserInvitationModalShow(false)} />
      </Container>
    </div>
  );
};

export default UserInvitationPage;
