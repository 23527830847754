import { useState, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import { GlobalContext } from '../../store/GlobalState';
import { apiUrl } from '../../utilities/requestHelpers';
import axios from "axios";
import './UploadFileForm.css';

const UploadFileForm = () => {
  const { jwtToken, addUpload } = useContext(GlobalContext);
  const [files, setFiles] = useState(null);
  const [validated, setValidated] = useState(false);

  const apiHeaders = { 'Authorization': `Bearer ${jwtToken}` };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);

      if (files !== null) {
        let formData = new FormData();
        
        // Add the file to the form data
        for (let file of files) {
          formData.append('files', file);
        }

        try {
          // Send data to the server
          let response = await axios.post(
            `${apiUrl}/files`,
            formData,
            {
              headers: {
                ...apiHeaders, 
              }
            }
          );

          if (response.status === 200) {
            addUpload(response.data);
            form.reset();
            setValidated(null);
          }
        }
        catch(error) {
          console.error('An error occured while uploading the file: %o', error.response);
        }
      }
    }
  }

  return (
    <Form className="fileupload-form" validated={validated} onSubmit={handleSubmit}>
      <Form.Group controlId="formFile" className="file-input">
        <Form.Control onChange={(e) => setFiles(e.target.files)} type="file" multiple />
      </Form.Group>

      <Button variant="outline-success" className='create-fileupload-button btn-block' type='submit'>Upload</Button>
    </Form>
  );
};

export default UploadFileForm;
