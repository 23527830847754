import { useContext, useState } from 'react';
import WorkItemReassignModal from '../../WorkItems/WorkItemReassignModal';
import WorkItemUpdateModal from '../../WorkItems/WorkItemUpdateModal';
import { GlobalContext } from '../../../store/GlobalState';
import { StatusType, LocationType } from '../../WorkItems/WorkItemTypes';
import Drawer from '../../Utilities/Drawers/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import './WorkItemDrawer.css';

const WorkItemDrawer = () => {
  dayjs.extend(dayjsLocalizedFormat);

  const {
    selectedWorkItem,
    assignWorkItem,
    hubConnection,
    users,
    isAdmin,
  } = useContext(GlobalContext);

  const [reassignModalShow, setReassignModalShow] = useState(false);
  const [updateModalShow, setUpdateModalShow] = useState(false);

  const handleClose = (e) => {
    e.preventDefault()
    assignWorkItem({})
  };

  const toDate = (dateString) => dateString !== null ? dayjs(dateString).format('LLL') : "N/A"

  // Using users in store to query this, works okay for now, should query API for this possibly :-/
  let assignedTo = users.find(u => u.id === selectedWorkItem.assignedTo);
  let assignedToAtntUserId = assignedTo?.attUserId !== "" ? ` (${assignedTo?.attUserId})` : '';

  let assignedToDisplay = selectedWorkItem.assignedTo !== null && assignedTo !== undefined
    ? (<>
        {assignedTo?.firstName}
        {assignedTo?.lastName}{assignedToAtntUserId}
        <a href={`mailto:${assignedTo?.email}`}>{`<${assignedTo?.email}>`}</a>
      </>)
    : (<>Unassigned</>);

    return (
      <Drawer overlay right className="work-item-drawer" >
        <FontAwesomeIcon icon={faTimes} className="work-item-close-drawer-button" onClick={handleClose}/>
        <h4>{selectedWorkItem?.locationId}</h4>
        <div className= "drawer-body">
          <p>{LocationType[selectedWorkItem?.locationDetails?.locationType]}</p>
          <hr />
          <p>{selectedWorkItem?.locationDetails?.address?.street}<br/>{selectedWorkItem?.locationDetails?.address?.city}, {selectedWorkItem?.locationDetails?.address?.state}</p>
          <hr/>
          <div className="date-section">
            <div className="date-line">
              <p>Pending Upload: </p>
              <p>{toDate(selectedWorkItem?.pendingUpload)}</p>
            </div>
            <div className="date-line">
              <p>Soft Close: </p>
              <p>{toDate(selectedWorkItem?.softClose)}</p>
            </div>
            <div className="date-line">
              <p>Closed: </p>
              <p>{toDate(selectedWorkItem?.closed)}</p>
            </div>
          </div>
          <hr/>
          
          {/* eslint-disable-next-line */}
          <p>Audit Quantity: {selectedWorkItem?.locationDetails?.quantityToBeDetermined === true ? 'TBD' : selectedWorkItem?.locationDetails?.auditQuantity }</p>
          <p>Assigned To: {assignedToDisplay}</p>
          &nbsp;
          &nbsp;
          <div className="work-item-button-container">
            {(selectedWorkItem?.itemStatus !== StatusType.RecentlyReconciled) && <button className="advancement-button" onClick={() => {hubConnection.invoke('UpdateWorkItem', selectedWorkItem?.id)}}>
              Advance
            </button>}

            {(selectedWorkItem?.itemStatus !== StatusType.PendingSoftClose) && <button className="rollback-button" onClick={() => {hubConnection.invoke('RollbackWorkItem', selectedWorkItem?.id)}}>
              Rollback
            </button>}
            <hr />
            {(isAdmin > 0 && selectedWorkItem?.itemStatus !== StatusType.RecentlyReconciled) && <button className="reassignment-button" onClick={() => setReassignModalShow(true)}>Reassign</button>}

            {(isAdmin > 1) && <button className="update-button" onClick={() => setUpdateModalShow(true)}>Update</button>}

            {(isAdmin > 1) && <button className="delete-button" onClick={() => { hubConnection.invoke('DeleteWorkItem', selectedWorkItem?.id); assignWorkItem({}) }}>Delete</button>}
          </div>
        </div>
        <WorkItemReassignModal show={reassignModalShow} onHide={() => setReassignModalShow(false)} />
        <WorkItemUpdateModal show={updateModalShow} onHide={() => setUpdateModalShow(false)} />
      </Drawer>
      
    )
};

export default WorkItemDrawer
