import { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { GlobalContext } from '../../../store/GlobalState';
import { apiUrl } from '../../../utilities/requestHelpers';
import { createToast } from '../../../utilities/toastHelpers';
import './Login.css';
import { getCookieValue } from '../../../utilities/cookieHelpers';

const Login = () => {
  const { jwtToken, assignJwtToken, toggleToasts } = useContext(GlobalContext);

  const [userName, setUserName] = useState('');
  const [pass, setPass] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const user = {
      username: userName,
      password: pass,
    };

    try {
      const result = await axios.post(
        `${apiUrl}/authentication/login`,
        user,
      );
  
      if (result.status === 200) {
        const data = await result.data;
        assignJwtToken(data.token, data.group);
        toggleToasts(createToast('success', 'Successfully logged in!'));
      }
    } catch (err) {
      toggleToasts(createToast('danger', 'Failed to log in.'));
    }
  };

  if ((jwtToken === false || jwtToken === null || jwtToken === '') && getCookieValue('telkoresched_token') === '') {
    return (
      <div className="form-page">
        <div className="form-container">
          <Form onSubmit={(e) => handleFormSubmit(e)}>
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Enter User Name" value={userName} onChange={(e) => setUserName(e.target.value)} />
            </Form.Group>
  
            <Form.Group className="mb-3">
              <Form.Control type="password" placeholder="Password" value={pass} onChange={(e) => setPass(e.target.value)} />
            </Form.Group>
  
            <button className="login-button" type="submit">
              <FontAwesomeIcon icon={faPowerOff} />
            </button>
          </Form>
        </div>
      </div>
    );
  } else return null;
};

export default Login;
