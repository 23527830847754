import { useContext } from 'react';
import { GlobalContext } from '../../../store/GlobalState';
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import UserToggle from '../../Users/UserToggle';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './UsersPage.css';

const UsersPage = () => {
  const { isAdmin, jwtToken, users } = useContext(GlobalContext);

  const userDisplayValues = {
    base: 'User',
    manager: 'Manager'
  };

  const columns = [
    { heading: 'Active', field: (row) => (<UserToggle key={row.id} user={row} />) },
    { heading: 'User Id', field: (row) => `${row.attUserId ?? row.email}` },
    { heading: 'Name', field: (row) => {
      let fullName = `${row?.firstName ?? ''} ${row?.lastName ?? ''}`;
      let email = row?.email !== null
        ? `<${row?.email}>`
        : '';

        return row?.email !== null
        ? (<a href={`mailto:${row?.email}`}>{fullName}{email}</a>)
        : `${fullName}`;
    } },
    { heading: 'Organization', field: (row) => `${row.organization}` },
    { heading: 'Roles', field: (row) => `${userDisplayValues[row.role]}`},
    { heading: 'Actions', field: (row) => (
      <>
        <Button 
          size="sm" 
          href={`${process.env.REACT_APP_BASE_APP_PATH}/users/${row.id}`}
          variant="outline-primary">
          <FontAwesomeIcon 
            title="Manage schedule" 
            size="md" 
            className="manage-user-schedule-icon" 
            icon={faClock} 
          /> Manage Schedule
        </Button>
      </>
    )}
  ];

  const tableHeading = (
    <tr>
      { columns.map((column) => <th>{column.heading}</th>) }
    </tr>
  );

  const tableData = (users.length > 0)
    ? (
      <Table striped bordered hover variant="dark">
        <thead>{ tableHeading }</thead>
        <tbody>{ users.map((row) => <tr key={row.id} className={`${!row.isActive ? 'text-muted' : ''}`}>{ columns.map((column) => <td className={`${!row.isActive ? 'text-muted' : ''}`}>{column.field(row)}</td>)}</tr>) }</tbody>
      </Table>)
    : (<p className="text-center">There are currently no users to manage.</p>);

  const pageBody = (jwtToken && isAdmin)
    ? (
      <>
        <div className="d-flex">
          <h1 className="p-2 flex-grow-1">Users</h1>
          <div className="p-2 align-self-center justify-content-center">&nbsp;</div>
        </div>
        { tableData }
      </>
    ) : (
      <p>You are not allowed to view this page.</p>
    )

  return (
    <div className="user-page">
      <Container>
        <Row>
          <Col>
            {pageBody}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UsersPage;
