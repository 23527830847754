import Drawer from './Drawer';
import { func } from 'prop-types';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = { toggleDrawer: func.isRequired };

const RightDrawer = ({ toggleDrawer }) => {
  <Drawer overlay right className="flex-column">
    <FontAwesomeIcon icon={faTimes} className="close-drawer-btn" onClick={() => toggleDrawer()} />

    <p>cOnTeNt</p>
  </Drawer>
};

RightDrawer.propTypes = propTypes;

export default RightDrawer;
