import { useContext, useEffect, useState } from "react";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import { GlobalContext } from "../../../store/GlobalState";
import dayjs from 'dayjs';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import UploadFileForm from '../../Uploads/UploadFileForm';
import { apiUrl } from "../../../utilities/requestHelpers";
import axios from "axios";
import './UploadPage.css';

const UploadsPage = () => {
  dayjs.extend(dayjsLocalizedFormat);
  const { 
        isAdmin, 
        jwtToken, 
        uploads,
        assignUploads,
        removeUpload
    } = useContext(GlobalContext);

  // --------------------------------------------------------------------------
  // State
  // eslint-disable-next-line
  const [uploadModalShow, setUploadModalShow] = useState(false);

  // --------------------------------------------------------------------------
  // API Calls

  const apiHeaders = { "Authorization": `Bearer ${jwtToken}` };

  const getUploads = () => {
      axios.get(
        `${apiUrl}/files`, 
        { headers: {...apiHeaders}})
        .then((response) => {
          if (response.status === 200) {
            assignUploads(response.data);
          }
        })
        .catch((err) => {
          console.warn('An error occurred while retrieving uploaded file list.\nError: %o', err);
        });
    };

    const getFile = async (e, uploadId) => { 
      e.preventDefault();
      
      if (uploadId !== null && uploadId !== undefined) {
        try {
          const response = await axios.get(
            `${apiUrl}/files/${uploadId}`,
            { 
              responseType: 'blob',
              headers: {...apiHeaders}
            }
          );
  
          if (response.status === 200) {
            console.log('response: %o', response);
            const blobUrl = URL.createObjectURL(new Blob([response.data]));
            const anchor = document.createElement('a');
            const fileName = uploads.filter(u => u.id === uploadId)[0].originalFileName;
            anchor.href = blobUrl;
            anchor.setAttribute('download', fileName);
            document.body.appendChild(anchor);
            anchor.click();
          }
          }
        catch (err) {
          console.error('Unable to download file.\nError: %o', err);
        }
      }
    };

    const removeFile = async (uploadId) => {
      if (uploadId !== null && uploadId !== undefined){
        try {
          const response = await axios.delete(
            `${apiUrl}/files/${uploadId}`,
            { headers: {...apiHeaders}}
          );

          if (response.status === 204) {
            removeUpload(uploadId);
          }
        }
        catch (err) {
          console.error('Unable to remove file.\nError: %o', err);
        }
      }
    }

    useEffect(() => {
      getUploads();
      // eslint-disable-next-line
    }, []); // called only once <- according to the docs!

  // --------------------------------------------------------------------------
  // User Interface

  const columns = [
        { heading: 'File', field: (row) => (<a href={row.id} title="Click to download" data-upload-id={row.id} onClick={(e) => getFile(e, encodeURIComponent(row.id))}>{row.originalFileName}</a>) },
        { heading: 'Date Uploaded', field: (row) => dayjs(row.dateCreated).format('LLL') }
    ];

    if (isAdmin === 2) {      
        columns.push({ heading: 'Actions', field: (row) => (<Button size="sm" variant="outline-danger" onClick={(e) => { e.stopPropagation(); removeFile(encodeURIComponent(row.id)); return false; }}>Remove</Button>) });
    }

    const tableHeading = (
        <tr>
            { columns.map((column) => <th>{column.heading}</th>) }
        </tr>
    );

    const tableData = (uploads?.length)
      ? (
        <Table id="uploadTable" striped bordered hover responsive variant="dark">
          <thead>{tableHeading}</thead>
          <tbody>{ uploads?.map((row) => <tr className="uploadTable__row">{ columns.map((column) => <td className="uploadTable__column">{column.field(row)}</td>)}</tr>) }</tbody>
        </Table>
      ) : (<p className="text-center">There are no uploads at this time.</p>);

    const pageBody = (
      <>
        <div className="d-flex">
          <h2 className="p-2 flex-grow-1">Files</h2>
        </div>
        { isAdmin === 2 ? <UploadFileForm /> : '' }
        { tableData }
      </>
    )

    return (
      <div className="user-invitation-page">
        <Container>
          <Row>
            <Col>
              {pageBody}
            </Col>
          </Row>
        </Container>
      </div>
    );
  
};

export default UploadsPage;
