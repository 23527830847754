/**
 * Convenience method to quickly create a toast notification object.
 * @param {String} type The type of toast you want to create. Usually 'success' or 'danger'.
 * @param {String} content The message you want the toast to display.
 * @returns The toast object to pass into toggleToasts.
 */
export const createToast = (type, content) => {
  const title = type === 'success' ? 'Success' : 'Error';

  return {
    type,
    title,
    content
  };
}
