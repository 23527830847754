import { useContext, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { node } from 'prop-types';
import { GlobalContext } from '../store/GlobalState';
import dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjsTimezone from 'dayjs/plugin/timezone';
import { DayOfWeek } from './UserSchedules/UserScheduleTypes';
import { hubUrl } from '../utilities/requestHelpers';
import { createToast } from '../utilities/toastHelpers';
import Login from './Pages/Login/Login';
import { getCookieValue, telkoreSchedToken, telkoreAdminFlag } from '../utilities/cookieHelpers';

const propTypes = { children: node };

const Body = ({ children }) => {
  dayjs.extend(dayjsUtc);
  dayjs.extend(dayjsTimezone);
  dayjs.extend(dayjsLocalizedFormat);

  const {
    jwtToken,
    isAdmin,
    assignJwtToken,
    hubConnection,
    assignHubConnection,
    assignWorkItems,
    assignUsers,
    assignWorkItem,
    assignInvitations,
    assignUserSchedules,
    assignTimeZones,
    toggleToasts,
    assignWorkItemFilter,
    assignRequestorId, } = useContext(GlobalContext);

  useEffect(() => {
    const getJwtToken = () => {
      // If we don't have a token, check for cookie.
      if (!jwtToken) {
        const cookie = getCookieValue(telkoreSchedToken);
        const isAdminCookie = getCookieValue(telkoreAdminFlag);
        // I hate cookies.
        //const isActuallyAdmin = isAdmin === 'true' ? true : false;
        // If cookie exists, set token in app.
        if (cookie) assignJwtToken(cookie, parseInt(isAdminCookie));
      }
    };

    getJwtToken();

    if (jwtToken && !hubConnection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${hubUrl}/work-item`, { accessTokenFactory: () => jwtToken })
        .configureLogging(LogLevel.Trace)
        .withAutomaticReconnect()
        .build();

      assignHubConnection(newConnection);
    }
  });

  useEffect(() => {
    if (hubConnection) {
      hubConnection.onreconnecting(() =>
        toggleToasts(
          createToast("danger", "Attemping to reconnect to server...")
        )
      );

      hubConnection.onreconnected(() => {
        toggleToasts(createToast("success", "Reconnected to server."));
        hubConnection.invoke("GetAllWorkItems");
        hubConnection.invoke("GetAllUsers");

        if (isAdmin === 2) hubConnection.invoke("GetUserInvitations");
      });

      hubConnection.onclose((error) => {
        toggleToasts(
          createToast(
            "danger",
            "Disconnected from server. Try refreshing the page or contact IT."
          )
        );
        console.warn(error);
      });

      if (hubConnection.state === HubConnectionState.Disconnected) {
        hubConnection
          .start()
          .then((result) => {
            console.log("Connected to server..");

            hubConnection.on("SuccessReceiveUsers", (result) =>
              assignUsers(result.data)
            );

            hubConnection.on("FailureReceiveUsers", (errors) =>
              console.warn(errors)
            );

            hubConnection.on("SuccessfulUserActivityToggle", (result) => {
              if (result.success) {
                toggleToasts(
                  createToast("success", "User successfully toggled")
                );
                hubConnection.invoke("GetAllUsers");
              }
            });

            hubConnection.on("FailureUserActivityToggle", (errors) => {
              toggleToasts(createToast("danger", "Something went wrong!"));
              console.warn(errors);
            });

            hubConnection.on("SuccessReceiveWorkItem", (result) =>
              assignWorkItem(result.data)
            );

            hubConnection.on("FailureReceiveWorkItem", (errors) => {
              toggleToasts(
                createToast("danger", "Something went wrong getting this item.")
              );
              console.warn(errors);
            });

            hubConnection.on("SuccessReceiveWorkItems", (result) => {
              assignWorkItems(result.data);

              const valueOfFilter = document.getElementById('user-filter-select');
              const idForFiltering = valueOfFilter.value !== 'All' ? valueOfFilter.value : '';
              // We need this for client-side filtering, incase they're a base user.
              assignRequestorId(result.requestorUserName);
              // We need this to persist our work item filtering if the dropdown has a value.
              assignWorkItemFilter(idForFiltering);
            });

            hubConnection.on("FailureReceiveWorkItems", (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast("danger", "Problem occurred retrieving work items.")
              );
            });

            hubConnection.on("CreateWorkItemSuccess", async (result) => {
              toggleToasts(createToast("success", "Work item created!"));
              if (result.success) hubConnection.invoke("GetAllWorkItems");
            });

            hubConnection.on("CreateWorkItemFailure", (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast("danger", "Problem occurred creating work item.")
              );
            });

            hubConnection.on("WorkItemUpdateSuccess", async (result) => {
              toggleToasts(createToast("success", "Work item updated!"));
              if (result.success) hubConnection.invoke('GetAllWorkItems');
            });

            hubConnection.on("WorkItemUpdateFailure", (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast('danger', 'Problem occurred updating work item.')
              );
            });

            hubConnection.on("WorkItemReassignmentSuccess", async (result) => {
              toggleToasts(createToast("success", "Work item reassigned!"));
              if (result.success) hubConnection.invoke("GetAllWorkItems");
              assignWorkItem(result.data);
            });

            hubConnection.on("WorkItemReassignmentFailure", (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast("danger", "Failure to reassign work item.")
              );
            });

            hubConnection.on("WorkItemAdvancedSuccess", async (result) => {
              toggleToasts(createToast("success", "Work item advanced!"));
              if (result.success) hubConnection.invoke("GetAllWorkItems");
              assignWorkItem(result.data);
            });

            hubConnection.on("WorkItemAdvancedFailure", async (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast("danger", "Failure to advance work item")
              );
            });

            hubConnection.on("SuccessfulWorkItemDeletion", async (result) => {
              toggleToasts(createToast("success", "Work item deleted."));
              if (result.success) hubConnection.invoke("GetAllWorkItems");
            });

            hubConnection.on("FailedWorkItemDeletion", async (errors) => {
              toggleToasts(
                createToast("danger", "Failure deleting work item.")
              );
              console.warn(errors);
            });

            hubConnection.on("WorkItemStatusTargetSuccess", async (result) => {
              toggleToasts(createToast("success", "Work item status updated."));
              hubConnection.invoke("GetAllWorkItems");
            });

            hubConnection.on("WorkItemStatusTargetFailure", async (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast("danger", "Failure to update work item.")
              );
            });

            hubConnection.on("WorkItemRollbackSuccess", async (result) => {
              if (result.success) {
                hubConnection.invoke("GetAllWorkItems");
                assignWorkItem(result.data);
              }
            });

            hubConnection.on("WorkItemRollbackFailure", async (errors) => {
              console.warn(errors);
              toggleToasts(
                createToast("danger", "Failure to rollback the work item.")
              );
            });

            // Email Invitations
            hubConnection.on(
              "SendNewEmailInvitationSuccess",
              async (result) => {
                if (result.success) {
                  console.info(result);
                  toggleToasts(
                    createToast("success", "Created user invitation!")
                  );
                  hubConnection.invoke("GetUserInvitations");
                }
              }
            );

            hubConnection.on(
              "SendNewEmailInvitationFailure",
              async (errors) => {
                console.warn(errors);
                toggleToasts(
                  createToast("danger", "Failed to create user invitation.")
                );
              }
            );

            hubConnection.on(
              "RetrieveUserInvitationsSuccess",
              async (result) => {
                if (result.success) {
                  assignInvitations(result.data);
                }
              }
            );

            hubConnection.on("RetrieveUserInvitationsFailure", (errors) =>
              console.warn(errors)
            );

            hubConnection.on(
              "SuccessfulReceiveUserSchedules",
              async (result) => {
                if (result.success) assignUserSchedules(result.data);
              }
            );

            hubConnection.on("FailureReceiveUserSchedules", (errors) =>
              console.warn(errors)
            );

            // Time Zone Ids
            hubConnection.on("SuccessGetTimeZoneIds", async (result) => {
              if (result.success) assignTimeZones(result.data);
            });

            hubConnection.on("FailureGetTimeZoneIds", (errors) =>
              console.warn(errors)
            );

            // User daily schedules
            hubConnection.on("SuccessfulUpdateUserSchedule", (result) => {
              if (result.success) {
                let dayNameOrDate = null;
                if (result.data.entryType !== 0)
                  dayNameOrDate = `for ${DayOfWeek[result.data.entryType]}`;
                else dayNameOrDate = "Exception date"; // figured this would be simpler. Existing code rendered an Invalid Date. ~ZB

                toggleToasts(
                  createToast("success", `Updated schedule ${dayNameOrDate}`)
                );
                hubConnection.invoke("GetSchedulesForUser", result.data.userId);
              }
            });

            hubConnection.on("SuccessfulUserScheduleCreation", (result) => {
              if (result.success) {
                toggleToasts(createToast("success", "Created schedule!"));
                hubConnection.invoke("GetSchedulesForUser", result.data.userId);
              }
            });

            hubConnection.on("FailureUserScheduleCreation", (errors) => {
              toggleToasts(createToast("danger", "Failed to create schedule"));
              console.warn(errors);
            });

            hubConnection.on("FailureUpdateUserSchedule", (errors) => {
              toggleToasts(createToast("danger", "Something went wrong!"));
              console.warn(errors);
            });

            hubConnection.on("SuccessfulDeleteUserSchedule", (result) => {
              if (result.success) {
                toggleToasts(
                  createToast("success", "Schedule deleted successfully.")
                );
                hubConnection.invoke("GetSchedulesForUser", result.data.userId);
              }
            });

            hubConnection.on("FailureDeleteUserSchedule", (errors) => {
              toggleToasts(createToast("danger", "Something went wrong!"));
              console.warn(errors);
            });

            try {
              hubConnection.invoke("GetAllWorkItems");
              hubConnection.invoke("GetAllUsers");
              if (isAdmin === 2) hubConnection.invoke("GetUserInvitations");
            } catch (err) {
              console.warn(err);
            }
          })
          .catch((e) => console.warn("Connection failed", e));
      }
    }
  });

  if (jwtToken || (!jwtToken && window.location.pathname.startsWith('/invitation'))) {
    return (
        <div className="body">{children}</div>
    );
  } else {
    return (
      <div className="body"><Login /></div>
    );
  }
};

Body.propTypes = propTypes;

export default Body;
