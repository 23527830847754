import { useState, useContext, useEffect } from 'react';
import { Col, Modal, Form, InputGroup, Row } from 'react-bootstrap';
import { GlobalContext } from '../../store/GlobalState';
import './WorkItemModal.css';

const WorkItemUpdateModal = (props) => {
  const { hubConnection, selectedWorkItem } = useContext(GlobalContext);
  const [locId, setLocId] = useState('');
  const [auditQty, setAuditQty] = useState('');
  const [quantityToBeDetermined, setQuantityToBeDetermined] = useState(false);
  const [locType, setLocType] = useState(-1);
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setLocId(selectedWorkItem?.locationId);
    setAuditQty(selectedWorkItem?.locationDetails?.auditQuantity);
    setQuantityToBeDetermined(selectedWorkItem?.locationDetails?.quantityToBeDetermined);
    setStreet(selectedWorkItem?.locationDetails?.address?.street);
    setCity(selectedWorkItem?.locationDetails?.address?.city);
    setState(selectedWorkItem?.locationDetails?.address?.state);
    setLocType(selectedWorkItem?.locationDetails?.locationType);
  }, [selectedWorkItem]);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false || locType === '-1') {
      e.stopPropagation();
      setValidated(false);

      if (locType === '-1')
        alert('Select a valid location type.');
    } else {
      setValidated(true);

      if (hubConnection) {
        const newWorkItem = {
          locationId: locId,
          locationDetails: {
            address: {
              street: street,
              city: city,
              state: state,
            },
            auditQuantity: parseInt(auditQty),
            quantityToBeDetermined: quantityToBeDetermined,
            locationType: parseInt(locType),
          },
        };

        try {
          await hubConnection.invoke("UpdateWorkItemData", selectedWorkItem.id, newWorkItem);
          await hubConnection.invoke('GetWorkItem', selectedWorkItem.id);
          setValidated(false);

          props.onHide(false);
        } catch (err) {
          console.warn(err);
        }
      }
    }
  };

  /**
   * Handles click event for Quantity TBD control
   * @param {Event} e - Event args
   */
  const quantityToBeDeterminedHandleClick = (e) => {
    setQuantityToBeDetermined(e.target.checked);
    if (quantityToBeDetermined === true) {
      setAuditQty(0);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-dark"
    >
      <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
        <Modal.Header closeButton className="modal-header-sticky">
          <Modal.Title id="contained-modal-title-vcenter">
            Update Work Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Location Id: </Form.Label>
            <Form.Control required type="text" placeholder="Enter Location Id" value={locId} onChange={(e) => setLocId(e.target.value)} />
            <Form.Control.Feedback type="invalid">
              Please provide a location id.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="quantityToBeDetermined-switch"
              checked={quantityToBeDetermined}
              onChange={quantityToBeDeterminedHandleClick}
              label="Quantity TBD"
            />
          </Form.Group>

          {quantityToBeDetermined
            ? (<></>)
            : (
            <Form.Group className="mb-3">
              <Form.Label>Audit Qty: </Form.Label>
              <Form.Control required type="number" placeholder="Enter audit quantity" value={auditQty} onChange={(e) => setAuditQty(e.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide an audity quantity.
              </Form.Control.Feedback>
            </Form.Group>)
          }

          <Form.Group className="mb-3">
            <Form.Label>Location Type: </Form.Label>
            <Form.Select defaultValue={locType.toString()} required aria-label="Location Type select" onChange={(e) => setLocType(e.target.value)}>
              <option key={-1} value="-1">Select Location type</option>
              <option key={3} value="3">ALC</option>
              <option key={0} value="0">Truck</option>
              <option key={1} value="1">StagingTelco</option>
              <option key={2} value="2">StorageWC</option>
            </Form.Select>
          </Form.Group>

          <span>Address</span>
          <hr />

          <InputGroup hasValidation>
            <Row>
            <Form.Group className="mb-3">
              <Form.Label>Street:</Form.Label>
              <Form.Control required type="text" placeholder="Enter street" value={street} onChange={(e) => setStreet(e.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide a street.
              </Form.Control.Feedback>
            </Form.Group>
            </Row>

            <Row>
            <Form.Group className="mb-3">
              <Form.Label>City:</Form.Label>
              <Form.Control required type="text" placeholder="Enter city" value={city} onChange={(e) => {setCity(e.target.value)}} />
              <Form.Control.Feedback type="invalid">
                Please provide a city.
              </Form.Control.Feedback>
            </Form.Group>
            </Row>

            <Row>
            <Form.Group className="mb-3">
              <Form.Label>State:</Form.Label>
              <Form.Control required type="text" placeholder="Enter state" value={state} onChange={(e) => {setState(e.target.value)}} />
              <Form.Control.Feedback type="invalid">
                Please provide a state.
              </Form.Control.Feedback>
            </Form.Group>
            </Row>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer className="modal-footer-sticky">
          <Row>
            <Col>
              <button className="create-workitem-button btn btn-success btn-block" type="submit">
                Update
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default WorkItemUpdateModal;
