import {
  ASSIGN_JWT_TOKEN,
  ASSIGN_ACTIVE_PAGE_TITLE,
  ASSIGN_NEW_HUB_CONN,
  ADD_TOAST,
  REMOVE_FIRST_TOAST,
  ASSIGN_WORK_ITEMS,
  ASSIGN_WORK_ITEM,
  ADD_WORK_ITEM,
  ASSIGN_WORK_ITEM_FILTER,
  ASSIGN_USERS,
  ASSIGN_INVITATIONS,
  ADD_INVITATION,
  RESEND_INVITATION,
  ASSIGN_UPLOADS,
  ADD_UPLOAD,
  REMOVE_UPLOAD,
  ASSIGN_USER_SCHEDULES,
  ASSIGN_TIME_ZONES,
  ASSIGN_REQUESTOR_ID,
} from "./ActionTypes";

/**
 * Global context reducer.
 * @param {*} state
 * @param {*} action
 * @returns new state
 */
const reducer = (state, action) => {
  switch (action.type) {
    case ASSIGN_JWT_TOKEN:
      return {
        ...state,
        jwtToken: action.payload.token,
        isAdmin: action.payload.adminFlag,
      };
    case ASSIGN_ACTIVE_PAGE_TITLE:
      return {
        ...state,
        activePageTitle: action.payload
      };
    case ASSIGN_REQUESTOR_ID:
      return {
        ...state,
        requestorId: action.payload,
      };
    case ASSIGN_NEW_HUB_CONN:
      return {
        ...state,
        hubConnection: action.payload,
      };
    case ADD_TOAST:
      const toastsToAdd = [...state.toasts, action.payload];

      return {
        ...state,
        toasts: toastsToAdd
      };
    case REMOVE_FIRST_TOAST:
      const newToasts = [...state.toasts];
      newToasts.shift();

      return {
        ...state,
        toasts: newToasts
      };
    case ASSIGN_WORK_ITEMS:
      return {
        ...state,
        workItems: action.payload.newWorkItems,
        pendingWorkItems: action.payload.pendingItems,
        softClosedWorkItems: action.payload.softClosedItems,
        recentlyClosedWorkItems: action.payload.recentlyClosedItems,
      };
    case ASSIGN_WORK_ITEM:
      return {
        ...state,
        selectedWorkItem: action.payload
      };
    case ASSIGN_WORK_ITEM_FILTER:
      return {
        ...state,
        workItemFilter: action.payload,
      };
    case ASSIGN_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ADD_WORK_ITEM:
      const workItems = [...state.workItems, action.payload];
      return {
        ...state,
        workItems: workItems,
      };
    case ASSIGN_INVITATIONS:
      return {
        ...state,
        invitations: action.payload.invitations,
      };
    case ADD_INVITATION:
      const invitationsWithNewInvitation = [...state.invitations, action.payload];
      return {
        ...state,
        invitations: invitationsWithNewInvitation
      };
    case RESEND_INVITATION:
      let invitationsWithResentUpdate = [...state.invitations.filter(i => i.id !== action.payload.id), action.payload];
      return {
        ...state,
        invitations : invitationsWithResentUpdate
      };
    case ASSIGN_UPLOADS:
      return {
        ...state,
        uploads: action.payload
      };
    case ADD_UPLOAD:
      const uploadsToAdd = Array.isArray(action.payload) ? [...action.payload] : [action.payload];
      const uploads = [...uploadsToAdd,...state.uploads];

      return {
        ...state,
        uploads: uploads
      };
    case REMOVE_UPLOAD:
      return {
        ...state,
        uploads: state.uploads.filter((upload => upload.id !== action.payload.uploadId))
      }
    case ASSIGN_USER_SCHEDULES:
      return {
        ...state,
        userSchedules: action.payload,
      };
    case ASSIGN_TIME_ZONES:
      return {
        ...state,
        timeZones: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
