import { object, func } from 'prop-types';
import './WorkItemPillBox.css';

const propTypes = { 
  workItem: object,
  onClick: func
};

const WorkItemPillBox = ({ workItem, onClick }) => {
  const dragStart = (e) => e.dataTransfer.setData('work_item_id', e.target.id);

  const dragOver = (e) => {
    e.stopPropagation();
  }

  return (
    <div
      id={workItem.id}
      className="work-item"
      onClick={onClick}
      draggable={true}
      onDragStart={dragStart}
      onDragOver={dragOver}>
      {workItem.locationId}
    </div>
  );
};

WorkItemPillBox.propTypes = propTypes;

export default WorkItemPillBox;
