import { useState, useEffect, useContext } from "react";
import { bool, func } from 'prop-types';
import { useParams } from 'react-router-dom';
import { GlobalContext } from "../../store/GlobalState";
import { Modal, Form } from "react-bootstrap";
import dayjs from 'dayjs';
import "./ExceptionScheduleModal.css";

const propTypes = {
  show: bool,
  onHide: func,
};

const propTypeDefaults = {
  show: false,
};

const ExceptionScheduleModal = ({ show, onHide }) => {
  const { hubConnection } = useContext(GlobalContext);

  const { userId } = useParams();

  const [allDay, setAllDay] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState("");
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      if (hubConnection) {
        const startDateTime = dayjs(`${startDate} ${startTime}`); //.format('HH:mm:ss')
        const endDateTime = dayjs(`${endDate} ${endTime}`); //.format('HH:mm:ss'));

        try {
          const exceptionSchedule = {
            userId,
            isActive,
            startDate,
            startTime: allDay ? null : startDateTime.format('HH:mm:ss'),
            endDate: endDate,
            endTime: allDay ? null : endDateTime.format('HH:mm:ss'),
            entryType: 0 // since this schedule entry is an exception
          };

          // eslint-disable-next-line
          let x = await hubConnection.invoke(
            "CreateUserSchedule",
            exceptionSchedule
          );

          // reset input fields
          setAllDay(false);
          setIsActive(false);
          setStartDate(new Date());
          setStartTime("");
          setEndDate(new Date());
          setEndTime("");
          setValidated(false);

          // close modal
          handleClose();
        } catch (err) {
          console.warn(err);
        }
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    onHide?.();
  };

  return (
    <Modal
      show={showModal}
      onHide={(e) => {handleClose()}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-dark"
    >
      <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
        <Modal.Header closeButton className="modal-header-sticky">
          <Modal.Title id="contained-modal-title-vcenter">
            Create Schedule Exceptions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="split-input">
            <Form.Group className="mb-3">
              <Form.Label>All Day</Form.Label>
              <Form.Check
                type="checkbox"
                value={allDay}
                onChange={() => setAllDay(!allDay)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Active</Form.Label>
              <Form.Check
                type="checkbox"
                value={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3 date-input">
            <div>
              <Form.Label>Start Date</Form.Label>
              <input
                style={{ display: "block" }}
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div>
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 date-input">
            <div>
              <Form.Label>End Date</Form.Label>
              <input
                style={{ display: "block" }}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div>
              <Form.Label>End Time</Form.Label>
              <Form.Control
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </div>
          </Form.Group>

          <button type="submit">Create</button>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

ExceptionScheduleModal.propTypes = propTypes;
ExceptionScheduleModal.propTypeDefaults = propTypeDefaults;

export default ExceptionScheduleModal;
