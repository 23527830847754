import { useState, useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { GlobalContext } from "../../store/GlobalState";
import dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as dayjsTimezone from 'dayjs/plugin/timezone';
import { DayOfWeek } from "./UserScheduleTypes";
import "./DailyScheduleModal.css";

const propTypes  = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};

const propTypeDefaults = {
  show: false,
};

const DailyScheduleModal = ({show,onHide}) => {
  dayjs.extend(dayjsUtc);
  dayjs.extend(dayjsTimezone);
  dayjs.extend(dayjsLocalizedFormat);

  const localNow = dayjs.tz();

  const { 
    userSchedules,
    timeZones,
    hubConnection
  } = useContext(GlobalContext);

  const [showModal, setShowModal] = useState(show);
  const [validated, setValidated] = useState(false);
  const [userDailySchedules, setUserDailySchedules] = useState([]);
  
  /**
   * Updates the specified schedule property
   * @param {string} scheduleId
   * @param {string} propName 
   * @param {*} value 
   */
  const updateSchedule = async (scheduleId, propName, value) => {
    const schedule = userDailySchedules.find(sched => sched.id === scheduleId);

    console.log(schedule);

    // const newSchedules = userDailySchedules.map(sched => {
    //   if (sched.id === scheduleId) {
    //     return {...sched, [propName]: value}
    //   }
    //   return sched;
    // });

    await setUserDailySchedules([...userDailySchedules.filter(sched => sched.id !== scheduleId), {...schedule, [propName]: value}].sort((a, b) => a.entryType - b.entryType));
  };

  /**
   * Converts the serialized value of NodaTime.LocalTime to a time input compatible format.
   * @param {string} localTime 
   * @returns {string}
   */
  const getTimeInputValue = (localTime) => {
    const parsedTime = dayjs(`${localNow.format('YYYY-MM-DD')} ${localTime}`, ['YYYY-MM-DD HH:mm:ss']);

    return parsedTime.isValid() ? parsedTime.format('HH:mm') : null;
  };

  const initUserDailySchedules = () => {
    const dailySchedules = userSchedules
    .filter((sched) => sched.entryType !== 0)
    .sort((a, b) => a.entryType - b.entryType);

    setUserDailySchedules(dailySchedules);
  }

 useEffect(() => {
  setShowModal(show);
  if (show) {
    initUserDailySchedules();
  }
  // eslint-disable-next-line
 }, [show])

  // TODO: Client-side validation.

  // Old way, may need to revisit:
  /**
   * if (HubConnection) {
   *   const schedules = [
   *     sunday,
   *     monday,
   *     tuesday,
   *     wednesday,
   *     thursday,
   *     friday,
   *     saturday
   *   ];
   * 
   *   try {
   *     await HubConnection.invoke('UpdateUserDailySchedules', schedules);
   *   setShowModal(false);
   *   }
   *   catch (err) {
   *     console.warn(err);
   *   }
   * }
  **/


  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(false);
    }
    else {
      setValidated(true);

      if (hubConnection) {
        try {
          for (const sched of userDailySchedules) {
            // eslint-disable-next-line
            let x = await hubConnection.invoke("UpdateUserSchedule", sched);
          }
          handleClose();
        }
        catch (err) {
          console.warn(err);
        }
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    onHide?.();
  };

  /* Note:
   * I'd like to refactor this into grouping the controls by day
   * However I'm not sure how complex it would be to do that.
   * -- Micah
   */

  const timeZoneOptions = timeZones.map((zoneId, index) => (<option key={index} value={zoneId}>{zoneId}</option>));

  return (
    <Modal
      show={showModal}
      onHide={(e) => {handleClose()}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-dark"
      dialogClassName="modal-65w"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="modal-header-sticky" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Daily Schedules
        </Modal.Title>
      </Modal.Header>
      <Form validated={validated} onSubmit={(e) => handleSubmit(e)}>
      <Modal.Body>
        {/* Begin EntryForm */}
        {userDailySchedules
          .map(sched => (
            <fieldset key={sched.id}>
              <Row className={!sched.isActive ? 'text-muted': ''}>
                <Col className={!sched.isActive ? 'opacity-50': ''}>
                  <legend className="h5">{DayOfWeek[sched.entryType]}</legend>
                </Col>
              </Row>
              <Row>
                <Col md="1">
                  <Form.Group className="mb-3">
                    <Form.Label className={!sched.isActive ? 'text-muted': ''}>Active</Form.Label>
                    <div>
                      <Form.Switch
                        inline
                        aria-label="Active"
                        checked={sched.isActive}
                        onChange={(e) => updateSchedule(sched.id, 'isActive', e.target.checked)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col className={!sched.isActive ? 'opacity-50': ''}>
                  <Form.Group className="mb-3">
                    <Form.Label className={!sched.isActive ? 'text-muted': ''}>Start Time</Form.Label>
                    <Form.Control
                      type="time"
                      placeholder="Enter start time (HH:mm:ss)"
                      value={getTimeInputValue(sched.startTime) ?? ''}
                      onChange={(e) => updateSchedule(sched.id, 'startTime', `${e.target.value}:00`)}
                      disabled={!sched.isActive}
                    />
                  </Form.Group>
                </Col>
                <Col className={!sched.isActive ? 'opacity-50': ''}>
                  <Form.Group className="mb-3">
                    <Form.Label className={!sched.isActive ? 'text-muted': ''}>End Time</Form.Label>
                    <Form.Control
                      type="time"
                      placeholder="Enter end time (HH:mm:ss)"
                      value={getTimeInputValue(sched.endTime) ?? ''}
                      onChange={(e) => updateSchedule(sched.id, 'endTime', `${e.target.value}:00`)}
                      disabled={!sched.isActive}
                    />
                  </Form.Group>
                </Col>
                <Col className={!sched.isActive ? 'opacity-50': ''}>
                  <Form.Group className="mb-3">
                    <Form.Label className={!sched.isActive ? 'text-muted': ''}>Timezone</Form.Label>
                    <Form.Select
                      value={sched.timeZone}
                      onChange={(e) => updateSchedule(sched.id, 'timeZone', e.target.value)}
                      disabled={!sched.isActive}
                    >
                      {timeZoneOptions}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {/* <div>
                {DayOfWeek[sched.entryType]} Data: <pre>{JSON.stringify(sched, null, 2)}</pre>
              </div> */}
            </fieldset>
          ))}
        {/* End EntryForm */}
      </Modal.Body>
      <Modal.Footer className="modal-footer-sticky">
        <Button variant="link" onClick={(e) => handleClose()}>Cancel</Button>
        <Button variant="outline-primary" type="submit">Update</Button>
      </Modal.Footer>
      </Form>
    </Modal>
  );
};

DailyScheduleModal.propTypes = propTypes;
DailyScheduleModal.propTypeDefaults = propTypeDefaults;

export default DailyScheduleModal;
