import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import { node } from 'prop-types';
import { ToastContainer, Toast } from 'react-bootstrap';
import {
  ASSIGN_JWT_TOKEN,
  ASSIGN_ACTIVE_PAGE_TITLE,
  ASSIGN_NEW_HUB_CONN,
  ADD_TOAST,
  REMOVE_FIRST_TOAST,
  ASSIGN_WORK_ITEMS,
  ASSIGN_WORK_ITEM,
  ADD_WORK_ITEM,
  ASSIGN_WORK_ITEM_FILTER,
  ASSIGN_USERS,
  ASSIGN_INVITATIONS,
  ASSIGN_UPLOADS,
  ADD_UPLOAD,
  REMOVE_UPLOAD,
  ASSIGN_USER_SCHEDULES,
  ASSIGN_TIME_ZONES,
  ASSIGN_REQUESTOR_ID,
} from './ActionTypes';
import { telkoreSchedToken, telkoreAdminFlag } from '../utilities/cookieHelpers';

// Initial state.
const initialState = {
  jwtToken: '',
  isAdmin: null,
  activePageTitle: '',
  hubConnection: null,
  toasts: [],
  selectedWorkItem: {},
  workItemFilter: '',
  workItems: [],
  pendingWorkItems: [],
  softClosedWorkItems: [],
  recentlyClosedWorkItems: [],
  users: [],
  invitations: [],
  timeZones: [],
  userSchedules: [],
  uploads: [],
  requestorId: '',
};

// Props
const propTypes = {
  children: node.isRequired
};

// Create context.
export const GlobalContext = createContext(initialState);

/**
 * Global Provider for our context. Go here to work with global state.
 */
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // --------------------------------------------------------------------------
  // Actions

  /**
   * This method either stores the token in state & sets a cookie in the browser with it, or deletes the
   * cookie ONLY IF an empty string is passed to this action. The idea is that passing an empty string
   * means we're logging out. Consequently, restore sensitive data to its original state so a logged-out
   * user can't still see it.
   * @param {string} token The token string to assign as our Jwt token.
   * @param {boolean} adminFlag Optional param for if this user is an admin.
   */
  const assignJwtToken = (token, adminFlag) => {
    if (token === "") {
      document.cookie = `${telkoreSchedToken}=;Path=/;Max-Age=-99999999;`;
      document.cookie = `${telkoreAdminFlag}=;Path=/;Max-Age=-99999999;`;
    } else {
      document.cookie = `${telkoreSchedToken}=${token};Path=/`;
      document.cookie = `${telkoreAdminFlag}=${adminFlag};Path=/`;
    }

    dispatch({
      type: ASSIGN_JWT_TOKEN,
      payload: { token, adminFlag},
    });
  };

  /**
   * Sets the active page title. The active page title gets used in places like the Toolbar.
   * @param {string} newTitle The new title to make our active page.
   */
  const assignActivePageTitle = (newTitle) => {
    dispatch({
      type: ASSIGN_ACTIVE_PAGE_TITLE,
      payload: newTitle,
    });
  };

  /**
   * Assigns the hub connection for us to plug any component we want into it.
   * @param {Object} newConn The signalR hub connection we store in state.
   */
  const assignHubConnection = (newConn) => {
    dispatch({
      type: ASSIGN_NEW_HUB_CONN,
      payload: newConn,
    });
  };

  /**
   * Dispatches the creation of a toast. After 3 seconds, dispatches to have the first toast in
   * state removed.
   * @param {Object} newToast An object of a toast. Object contains toast type, title and content.
   */
  const toggleToasts = async (newToast) => {
    await dispatch({
      type: ADD_TOAST,
      payload: newToast,
    });

    setTimeout(() =>  {
      dispatch({ type: REMOVE_FIRST_TOAST });
    }, 3000);
  };

  /**
   * Dispatches work items to assign to state.
   * @param {Array} newWorkItems The array of work items we're commiting to state.
   */
  const assignWorkItems = (newWorkItems) => {
    const pendingItems = newWorkItems.filter((workItem) => workItem.itemStatus === 0);
    const softClosedItems = newWorkItems.filter((workItem) => workItem.itemStatus === 1);
    const recentlyClosedItems = newWorkItems.filter((workItem) => workItem.itemStatus === 2);

    dispatch({
      type: ASSIGN_WORK_ITEMS,
      payload: {
        newWorkItems,
        pendingItems,
        softClosedItems,
        recentlyClosedItems,
      },
    });
  };

  /**
   * Dispatches a user id to state as the requestor of data.
   * @param {String} newId The id of the user requesting data.
   */
  const assignRequestorId = (newId) => {
    dispatch({
      type: ASSIGN_REQUESTOR_ID,
      payload: newId,
    });
  };

  /**
   * Sets the active work item being viewed in the drawer.
   * @param {Object} newWorkItem The work item we're assigning as active.
   */
  const assignWorkItem = (newWorkItem) => {
      dispatch({
        type: ASSIGN_WORK_ITEM,
        payload: newWorkItem,
      });
  }

  /**
   * Currently unused method to add a work item to our state.
   * @param {Object} newWorkItem The work item to add to our list of work item.
   */
  const addWorkItem = (newWorkItem) => {
    const workItemToAdd = {
      id: newWorkItem.id,
      locationId: newWorkItem.locationId,
      itemStatus: newWorkItem.itemStatus
    };

    dispatch({
      type: ADD_WORK_ITEM,
      payload: workItemToAdd
    });
  }

  const assignWorkItemFilter = (username) => {
    dispatch({
      type: ASSIGN_WORK_ITEM_FILTER,
      payload: username
    });
  };

  /**
   * Sets the list of user emails and ids that we can reassign work items to.
   * @param {Array} users The array of users we're commiting to state.
   */
  const assignUsers = (users) => {
    const sortedUsers = users.sort((a, b) => {
      if (a.email < b.email)
        return -1;
      else if (a.email > b.email)
        return 1;
      else
        return 0;
    });

    dispatch({
      type: ASSIGN_USERS,
      payload: sortedUsers,
    });
  }

  /**
   * Sets the list of invitations.
   * @param {object[]} invitations The array of invitations to be committed to state.
   */
  const assignInvitations = (invitations) => {
    if (invitations.length !== 0) {
      dispatch({
        type: ASSIGN_INVITATIONS,
        payload: {invitations}
      });
    }
  };

  /**
   * Sets the list of file uploads.
   * @param {array} uploads The array of uploads to be committed to state.
   */
  const assignUploads = (uploads) => {
    if (uploads.length !== 0) {
      dispatch({
        type: ASSIGN_UPLOADS,
        payload: uploads,
      });
    }
  }

  const addUpload = (newUpload) => {
    if (newUpload !== null && newUpload !== undefined) {
      dispatch({
        type: ADD_UPLOAD,
        payload: newUpload,
      });
    }
  }
  
  const removeUpload = (uploadId) => {
    if (uploadId !== null && uploadId !== undefined) {
      dispatch({
        type: REMOVE_UPLOAD,
        payload: { uploadId }
      });
    }
  }

  const assignUserSchedules = (userSchedulesToAdd) => {
    if (userSchedulesToAdd.length !== 0) {
      dispatch({
        type: ASSIGN_USER_SCHEDULES,
        payload: userSchedulesToAdd,
      });
    }
  };

  const assignTimeZones = (timeZones) => {
    if (timeZones !== undefined && timeZones?.length !== 0) {
      dispatch({
        type: ASSIGN_TIME_ZONES,
        payload: timeZones
      });
    }
  }

  // --------------------------------------------------------------------------

  const stateObj = {
    jwtToken: state.jwtToken,
    isAdmin: state.isAdmin,
    activePageTitle: state.activePageTitle,
    hubConnection: state.hubConnection,
    toasts: state.toasts,
    workItems: state.workItems,
    workItemFilter: state.workItemFilter,
    selectedWorkItem: state.selectedWorkItem,
    pendingWorkItems: state.pendingWorkItems,
    softClosedWorkItems: state.softClosedWorkItems,
    recentlyClosedWorkItems: state.recentlyClosedWorkItems,
    users: state.users,
    invitations: state.invitations,
    uploads: state.uploads,
    timeZones: state.timeZones,
    userSchedules: state.userSchedules,
    requestorId: state.requestorId,
    assignRequestorId,
    assignJwtToken,
    assignHubConnection,
    assignActivePageTitle,
    toggleToasts,
    assignWorkItems,
    assignWorkItem,
    assignWorkItemFilter,
    addWorkItem,
    assignUsers,
    assignInvitations,
    assignUploads,
    addUpload,
    removeUpload,
    assignUserSchedules,
    assignTimeZones,
  };

  return (
    <GlobalContext.Provider value={stateObj}>
      {children}
      <ToastContainer className="p-3" position='bottom-end' style={{ zIndex: 1052 }}>
        {stateObj.toasts.map((toast, index) => (
          <Toast
            variant={toast.type}
            animation={true}
            key={index}
            className={`${toast.type} toast`}
          >
            <Toast.Header closeButton={false}>
              <strong className="toast-title">{toast.title}</strong>
              <small className="toast-date">just now</small>
            </Toast.Header>
            <Toast.Body>{toast.content}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = propTypes;
